
function getScrollTop () {
  return window.pageYOffset || document.documentElement.scrollTop;
}

class Hero {
  constructor() {

    let hero = this;

    let heroFg = document.getElementById('hero-fg-layer');
    let trail = document.getElementById('trail');
    let gusContainer = document.getElementById('pokemon-gus-container');
    let nintendoSwitchLogo = document.getElementById('nintendo-switch-logo');
    let desktopNav = document.getElementById('desktop-nav');
    let mobileNav = document.getElementById('mobile-nav');

    let followers = [
      heroFg,
      trail,
      gusContainer,
      nintendoSwitchLogo,
      desktopNav,
      mobileNav
    ];

    let scrollTop = getScrollTop();
    hero.isFixed = true;

    window.addEventListener('scroll', checkScroll);
    window.addEventListener('resize', checkScroll);
    checkScroll();

    function checkScroll() {
      scrollTop = getScrollTop();
      // console.log('scrollTop', scrollTop);

      let width = window.innerWidth;
      // default threshold to 645 for devices wider than 1024
      let threshold = 645;
      // scale range 1024 to 768
      if (width < 1024 && width >= 768) {
        threshold = threshold * (width / 1024);
      }
      // change default threshold for mobile layouts
      if (width < 768) {
        threshold = 760;
      }
      // scale from 540 down on mobile
      if (width < 540) {
        threshold = threshold * (width / 540);
      }

      if (hero.isFixed) {
        // make 645 a dynamic variable to account for responsive layout
        if (scrollTop >= threshold) {
          hero.isFixed = false;
          followers.forEach(el => {
            el.classList.add('is-absolute');
          });
          hero.update();
        }
      } else {
        if (scrollTop < threshold) {
          hero.isFixed = true;
          followers.forEach(el => {
            el.classList.remove('is-absolute');
          });
          hero.update();
        }
      }
    }
  }
  update () {
    let hero = this;
    if (hero.onHeroUpdate) {
      hero.onHeroUpdate( hero.isFixed );
    }
  }
  getIsFixed () {
    return this.isFixed;
  }
}

export default Hero;
