import { qsArray, debounce } from "../utilities";

window.dataLayer = window.dataLayer || [];

class Quiz {
  constructor() {
    let quiz = this;
    quiz.wrap = document.getElementById('quiz-wrap');

    this.initializePersonality();

    // console.log('Constructor', this);
  }
  initializePersonality () {
    let quiz = this;
    let parent = document.getElementById('personality-quiz');
    let statements = qsArray('.statements li', parent);
    let bullets = qsArray('.bullet', parent);
    let pokemon = qsArray('#lead-pokemon .graphic');
    let pokemonMobile = qsArray('#lead-pokemon-mobile .graphic');
    let resultNames = qsArray('#result-name span');
    let left = parent.querySelector('.button-arrow-left');
    let right = parent.querySelector('.button-arrow-right');
    let confirm = parent.querySelector('.confirm');
    let mysteryCharacter = document.getElementById('mystery-character');
    let mysteryCharacterMobile = document.getElementById('mystery-character-mobile');
    let index = 0;
    let prevIndex = 0;
    let length = statements.length;
    let completed = false;

    left.addEventListener('click', function () {
      index = index > 0 ? index - 1 : length - 1;
      update();
    });
    right.addEventListener('click', function () {
      index = index + 1 < length ? index + 1 : 0;
      update();
    });
    bullets.forEach(b => {
      b.addEventListener('click', () => {
        index = parseInt(b.getAttribute('data-index'));
        update();
      })
    });
    confirm.addEventListener('click', onConfirm);

    function onConfirm () {
      if (completed) {
        return;
      }
      completed = true;
      let result = statements[index].getAttribute('data-result');
      // console.log('Lead Pokemon Selected', result);
      localStorage.setItem('lead-pokemon', result);
      window.dataLayer.push({
        'event': 'personality-select',
        'pokemonId': result
      });
      resultNames[index].classList.add('is-active');
      pokemon.forEach((p, i) => {
        if (p.classList.contains(result)) {
          let pM = pokemonMobile[i];
          mysteryCharacter.classList.remove('is-visible');
          mysteryCharacterMobile.classList.remove('is-visible');
          p.classList.add('is-active');
          pM.classList.add('is-active');
          p.clientHeight;
          pM.clientHeight;
          p.classList.add('is-visible');
          pM.classList.add('is-visible');
          parent.classList.remove('is-visible');
          setTimeout(function () {
            parent.classList.remove('is-active');
            quiz.initializePartner();
            quiz.onQuizComplete();
          }, 250);
        }
      });
    }

    // onConfirm();

    function update () {
      statements[prevIndex].classList.remove('is-active');
      bullets[prevIndex].classList.remove('is-active');
      statements[index].classList.add('is-active');
      bullets[index].classList.add('is-active');
      prevIndex = index;
    }

    // display the first option by default
    update();
  }
  initializePartner () {
    let parent = document.getElementById('partner-selector');
    let left = parent.querySelector('.button-arrow-left');
    let right = parent.querySelector('.button-arrow-right');
    let options = qsArray('.options li', parent);
    let partners = qsArray('#partner-pokemon .graphic');
    let partnersMobile = qsArray('#partner-pokemon-mobile .graphic');
    let length = options.length;
    let index = 0;
    let prevIndex = 0;

    parent.classList.add('is-active');
    parent.clientHeight;
    parent.classList.add('is-visible');

    left.addEventListener('click', function () {
      index = index > 0 ? index - 1 : length - 1;
      setPartner();
    });
    right.addEventListener('click', function () {
      index = index + 1 < length ? index + 1 : 0;
      setPartner();
    });

    let trackPartner = debounce(function( result ) {
      window.dataLayer.push({
        'event': 'partner-select',
        'pokemonPartnerId': result // unsure if the identity of the partner pokemon can be passed here since all we have are arrows
      });
    }, 1000);

    function setPartner () {
      options[prevIndex].classList.remove('is-active');
      partners[prevIndex].classList.remove('is-visible');
      partnersMobile[prevIndex].classList.remove('is-visible');
      options[index].classList.add('is-active');
      partners[index].classList.add('is-visible');
      partnersMobile[index].classList.add('is-visible');
      prevIndex = index;
      let result = options[index].getAttribute('data-option');
      trackPartner(result);
      // console.log('Partner Pokemon Selected', result);
      localStorage.setItem('partner-pokemon', result);
    }

    setPartner();
  }
}

export default Quiz;
