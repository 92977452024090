import { qsArray, getRandomInt } from "../utilities";

const LEAD_POKEMON = [
  "Torchic",
  "Pikachu",
  "Eevee",
  "Psyduck"
];

const PARTNER_POKEMON = [
  "Cyndaquil",
  "Totodile",
  "Mudkip",
  "Chikorita",
  "Charmander",
  "Machop",
  "Skitty",
  "Meowth"
];

class SideCharacters {
  constructor() {

    let parent = document.getElementById('sub-path-wrap');
    let characters = qsArray('.character-wrap', parent);
    let graphics = qsArray('.graphic', parent);
    let leadPokemon = qsArray('.poke-lead');
    let partnerPokemon = qsArray('.poke-partner');
    let lead = localStorage.getItem('lead-pokemon');
    let partner = localStorage.getItem('partner-pokemon');

    if (lead === null) {
      let randomIndex = getRandomInt(0, LEAD_POKEMON.length - 1);
      lead = LEAD_POKEMON[randomIndex];
    }
    if (partner === null) {
      let randomIndex = getRandomInt(0, PARTNER_POKEMON.length - 1);
      partner = PARTNER_POKEMON[randomIndex];
    }

    leadPokemon.forEach(el => {
      let children = qsArray('.character', el);
      children.forEach(c => {
        if (c.classList.contains(lead)) {
          c.classList.add('is-active');
        }
      });
    });

    partnerPokemon.forEach(el => {
      let children = qsArray('.character', el);
      children.forEach(c => {
        if (c.classList.contains(partner)) {
          c.classList.add('is-active');
        }
      });
    });

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0, 0.25, 0.5, 0.75, 1]
    };
    let contentObserver = new IntersectionObserver(handleContentIntersect, options);

    graphics.forEach(function(graphic){
      contentObserver.observe(graphic);
    });

    function handleContentIntersect (entries) {
      entries.forEach(entry => {
        let el = entry.target;
        let parent = el.parentNode.parentNode;
        if (entry.intersectionRatio >= 0.5 && !parent.classList.contains('is-visible')) {
          parent.classList.add('is-visible');
        } else if (entry.intersectionRatio <= 0.25 && entry.boundingClientRect.top > 0 && parent.classList.contains('is-visible')) {
          parent.classList.remove('is-visible');
        }
      });
    }

    console.log('Constructor', this);
  }
}

export default SideCharacters;
