import StorageCarousel from "./storage-carousel";
import PokemonCarousel from "./pokemon-carousel";

class World {
  constructor() {

    this.storageCarousel = new StorageCarousel();
    this.pokemonCarousel = new PokemonCarousel();

    console.log('Constructor', this);
  }
}

export default World;
