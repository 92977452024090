import { qsArray } from "../utilities";

class PokemonCarousel {
  constructor() {
    let parent = document.getElementById('pokemon-carousel');
    let left = parent.querySelector('.button-arrow-left');
    let right = parent.querySelector('.button-arrow-right');
    let bullets = qsArray('.bullet', parent);
    let items = qsArray('.carousel-content .item');
    let length = items.length;
    let index = 0;
    let prevIndex = 0;

    left.addEventListener('click', function () {
      index = index > 0 ? index - 1 : length - 1;
      update();
    });
    right.addEventListener('click', function () {
      index = index + 1 < length ? index + 1 : 0;
      update();
    });

    bullets.forEach(b => {
      b.addEventListener('click', () => {
        index = parseInt(b.getAttribute('data-index'));
        update();
      })
    });

    function update () {
      items[prevIndex].classList.remove('is-visible');
      items[prevIndex].classList.remove('is-active');
      bullets[prevIndex].classList.remove('is-active');
      items[index].classList.add('is-active');
      bullets[index].classList.add('is-active');
      items[index].clientHeight;
      items[index].classList.add('is-visible');
      prevIndex = index;
    }

    update();

    console.log('Constructor', this);
  }
}

export default PokemonCarousel;
