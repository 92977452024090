class MobileNav {
  constructor(props) {
    let mobileNav = document.getElementById('mobile-nav');
    let mobileNavShelf = document.getElementById('mobile-nav-shelf');
    let open = document.getElementById('mobile-nav-open');
    let close = document.getElementById('mobile-nav-close');

    open.addEventListener('click', function () {
      mobileNavShelf.classList.add('is-active');
    });

    close.addEventListener('click', function () {
      mobileNavShelf.classList.remove('is-active');
    });
  }
}

export default MobileNav;
