import { TimelineMax, Power2, CSSPlugin } from 'gsap/all';
import WalkTl from "./walk";

const plugins = [ CSSPlugin ];

// Trail Positions

// Start
// Lead  -16%, 128% // Partner 40%, 138%

// contentA step 1
// Lead  -56%, 142% // Partner 56%, 128%
// contentA step 2
// Lead  -25%, 208% // Partner -25%, 185%
// contentA Rest
// Lead  -68%, 280% // Partner -37%, 224%

// contentB step 1
// Lead  -50%, 307% // Partner -70%, 276%
// contentB step 2
// Lead  116%, 348% // Partner 82%, 312%
// contentB step 3
// Lead  116%, 424% // Partner 126%, 398%
// contentB step 4
// Lead  68%, 438% // Partner 98%, 426%
// contentB Rest
// Lead  68%, 482% // Partner 94%, 430%

// contentC step 1
// Lead  42%, 554% // Partner 54%, 530%
// contentC step 2
// Lead  -48%, 538% // Partner -52%, 518%
// contentC step 3
// Lead  -23%, 620% // Partner -28%, 594%
// contentC Rest
// Lead  -48%, 664% // Partner -20%, 610%

// contentD step 1
// Lead  -70%, 710% // Partner -74%, 680%
// contentD step 2
// Lead  74%, 750% // Partner 58%, 728%
// contentD step 3
// Lead  74%, 822% // Partner 78%, 794%
// contentD step 4
// Lead  24%, 832% // Partner 28%, 810%
// contentD Rest
// Lead  54%, 882% // Partner 28%, 826%

class Trail {
  constructor() {
    let trail = this;
    trail.scrollReady = false;
    trail.quizReady = false;

    let pokemon = document.getElementById('pokemon-characters-desktop');
    let lead = document.getElementById('lead-pokemon');
    let partner = document.getElementById('partner-pokemon');
    let Treecko = pokemon.querySelector('.Treecko');
    let Squirtle = pokemon.querySelector('.Squirtle');
    let Bulbasaur = pokemon.querySelector('.Bulbasaur');
    let Cubone = pokemon.querySelector('.Cubone');

    trail.selectors = {
      lead,
      Treecko,
      Squirtle,
      Bulbasaur,
      Cubone
    };

    let tl = new TimelineMax();

    // Start position
    tl.to(lead, 0, {x: '-16%', y: '128%'});
    tl.to(partner, 0, {x: '40%', y: '138%'});
    tl.addLabel('content-0');

    // contentA step 1
    tl.to(lead, 1, {x: '-56%', y: '142%'});
    tl.to(partner, 1, {x: '-56%', y: '128%'}, '-=' + 0.5);
    // contentA step 2
    tl.to(lead, 1, {x: '-25%', y: '208%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '-25%', y: '185%'}, '-=' + 0.5);
    // contentA Position
    tl.to(lead, 1, {x: '-68%', y: '280%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '-37%', y: '224%', onReverseComplete: () => {
        Treecko.classList.remove('show-speech-bubble');
        lead.classList.remove('show-exclamation');
      }}, '-=' + 0.5);
    tl.addLabel('content-a');

    // contentB step 1
    tl.to(lead, 1, {x: '-50%', y: '307%', onStart: () => {
        Treecko.classList.remove('show-speech-bubble');
        lead.classList.remove('show-exclamation');
      }});
    tl.to(partner, 1, {x: '-70%', y: '276%'}, '-=' + 0.5);
    // contentB step 2
    tl.to(lead, 1, {x: '116%', y: '348%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '82%', y: '312%'}, '-=' + 0.5);
    // contentB step 3
    tl.to(lead, 1, {x: '116%', y: '424%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '126%', y: '398%'}, '-=' + 0.5);
    // contentB step 4
    tl.to(lead, 1, {x: '68%', y: '438%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '98%', y: '426%'}, '-=' + 0.5);
    // contentB Position
    tl.to(lead, 1, {x: '68%', y: '482%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '94%', y: '430%', onReverseComplete: () => {
        Squirtle.classList.remove('show-speech-bubble');
        lead.classList.remove('show-exclamation');
      }}, '-=' + 0.5);
    tl.addLabel('content-b');

    // contentC step 1
    tl.to(lead, 1, {x: '42%', y: '554%', onStart: () => {
        Squirtle.classList.remove('show-speech-bubble');
      }});
    tl.to(partner, 1, {x: '54%', y: '530%'}, '-=' + 0.5);
    // contentC step 2
    tl.to(lead, 1, {x: '-48%', y: '538%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '-52%', y: '518%'}, '-=' + 0.5);
    // contentC step 3
    tl.to(lead, 1, {x: '-23%', y: '620%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '-28%', y: '594%'}, '-=' + 0.5);
    // contentC Position
    tl.to(lead, 1, {x: '-59%', y: '688%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '-28%', y: '638%', onReverseComplete: () => {
        Bulbasaur.classList.remove('show-speech-bubble');
        lead.classList.remove('show-exclamation');
      }}, '-=' + 0.5);
    tl.addLabel('content-c');

    // contentD step 1
    tl.to(lead, 1, {x: '-70%', y: '710%', onStart: () => {
        Bulbasaur.classList.remove('show-speech-bubble');
        lead.classList.remove('show-exclamation');
      }});
    tl.to(partner, 1, {x: '-74%', y: '680%'}, '-=' + 0.5);
    // contentD step 2
    tl.to(lead, 1, {x: '74%', y: '750%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '58%', y: '728%'}, '-=' + 0.5);
    // contentD step 3
    tl.to(lead, 1, {x: '74%', y: '822%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '78%', y: '794%'}, '-=' + 0.5);
    // contentD step 4
    tl.to(lead, 1, {x: '24%', y: '832%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '28%', y: '810%'}, '-=' + 0.5);
    // contentD Position
    tl.to(lead, 1, {x: '54%', y: '882%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '28%', y: '826%', onReverseComplete: () => {
        Cubone.classList.remove('show-speech-bubble');
        lead.classList.remove('show-exclamation');
      }}, '-=' + 0.5);
    tl.addLabel('content-d');

    tl.pause();

    trail.tl = tl;
    trail.tween = null;

    // console.log('Constructor', this);
  }
  setTarget ( target ) {
    let trail = this;
    let tl = trail.tl;
    let {
      lead,
      Treecko,
      Squirtle,
      Bulbasaur,
      Cubone
    } = trail.selectors;

    target = trail.scrollReady ? target : 'content-0';

    if (!trail.scrollReady || !trail.quizReady) {
      target = 'content-0';
    }

    tl.pause();
    if (trail.tween) {
      trail.tween.kill();
      trail.tween = null;
    }

    // TODO Walking animation

    // console.log('Trail.setTarget', target);
    trail.tween = tl.tweenTo(target, { onComplete: function () {
      // TODO Stop walking animation
      // console.log('Trail tweenTo complete');
        switch (target) {
          case 'content-a':
            Treecko.classList.add('show-speech-bubble');
            lead.classList.add('show-exclamation');
            break;
          case 'content-b':
            Squirtle.classList.add('show-speech-bubble');
            lead.classList.add('show-exclamation');
            break;
          case 'content-c':
            Bulbasaur.classList.add('show-speech-bubble');
            lead.classList.add('show-exclamation');
            break;
          case 'content-d':
            Cubone.classList.add('show-speech-bubble');
            lead.classList.add('show-exclamation');
            break;
        }
    }});
  }
  heroListen ( isHeroFixed ) {
    let trail = this;

    if (isHeroFixed) {
      trail.scrollReady = false;
      trail.setTarget('content-0');
    } else {
      trail.scrollReady = true;
    }

  }
  quizComplete () {
    let trail = this;
    trail.quizReady = true;
  }
}

export default Trail;
