import VideoPlayer from "./components/video";
import LeavingSite from "./components/leaving-site";
import LocaleSelector from "./components/locale-selector";
import WatercolorFx from "./components/watercolor-fx";
import IsVisible from "./components/is-visible";
import MobileNav from "./components/mobile-nav";
import smoothscroll from "./components/smoothscroll";
import ScrollArrow from "./components/scroll-arrow";
import SideCharacters from "./components/side-characters";
import Home from "./home";
import World from "./world";

$(function(){
  $(".pokemon-gus-container").GlobalUtilityStrip();

  // initialize the smooth scrolling polyfill
  smoothscroll.polyfill();

  document.body.classList.add('dom-ready');

  window.trailer = new VideoPlayer();
  new LeavingSite();
  new LocaleSelector();
  new WatercolorFx();
  new MobileNav();
  new IsVisible();
  new ScrollArrow();

  let page = document.body.getAttribute('data-page');
  let desktopNav = document.getElementById('desktop-nav');
  if (page === 'article') {
    desktopNav.querySelector('.nav-news').classList.add('is-active');
  } else {
    desktopNav.querySelector(`.nav-${page}`).classList.add('is-active');
  }

  switch (page) {
    case 'home':
      let home = new Home();
      break;
    case 'world':
      let world = new World();
      new SideCharacters();
      break;
    case 'gameplay':
      new SideCharacters();
      break;
    case 'news':
      new SideCharacters();
      break;
  }

});
