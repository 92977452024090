import { TimelineMax, Power2, CSSPlugin } from 'gsap/all';
import WalkTl from "./walk";

const plugins = [ CSSPlugin ];

class TrailMobile {
  constructor(props) {
    let trail = this;
    trail.scrollReady = false;
    trail.quizReady = false;

    let pokemon = document.getElementById('pokemon-characters-mobile');
    let lead = document.getElementById('lead-pokemon-mobile');
    let partner = document.getElementById('partner-pokemon-mobile');

    trail.selectors = {
      lead
    };

    let tl = new TimelineMax();

    // Start position
    tl.to(lead, 0, {x: '-24%', y: '246%'});
    tl.to(partner, 0, {x: '32%', y: '238%'});
    tl.addLabel('content-0');

    // contentA step 1
    tl.to(lead, 1, {x: '-176%', y: '292%'});
    tl.to(partner, 1, {x: '-176%', y: '292%'}, '-=' + 0.5);
    // contentA step 2
    tl.to(lead, 1, {x: '-176%', y: '486%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '-176%', y: '486%'}, '-=' + 0.5);
    // contentA Position
    tl.to(lead, 1, {x: '24%', y: '544%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '-36%', y: '540%', onReverseComplete: () => {
      lead.classList.remove('show-exclamation');
    }}, '-=' + 0.5);
    tl.addLabel('content-a');

    // contentB step 1
    tl.to(lead, 1, {x: '180%', y: '544%', onStart: () => {
      lead.classList.remove('show-exclamation');
    }});
    tl.to(partner, 1, {x: '180%', y: '540%'}, '-=' + 0.5);
    // contentB step 2
    tl.to(lead, 1, {x: '180%', y: '750%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '180%', y: '750%'}, '-=' + 0.5);
    // contentB Position
    tl.to(lead, 1, {x: '-28%', y: '800%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '30%', y: '782%', onReverseComplete: () => {
        lead.classList.remove('show-exclamation');
      }}, '-=' + 0.5);
    tl.addLabel('content-b');

    // contentC step 1
    tl.to(lead, 1, {x: '-176%', y: '822%', onStart: () => {
      lead.classList.remove('show-exclamation');
    }});
    tl.to(partner, 1, {x: '-176%', y: '822%'}, '-=' + 0.5);
    // contentC step 2
    tl.to(lead, 1, {x: '-176%', y: '1004%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '-176%', y: '1004%'}, '-=' + 0.5);
    // contentC Position
    tl.to(lead, 1, {x: '30%', y: '1030%'}, '-=' + 0.5);
    tl.to(partner, 1, {x: '-30%', y: '1022%', onReverseComplete: () => {
        lead.classList.remove('show-exclamation');
      }}, '-=' + 0.5);
    tl.addLabel('content-c');

    tl.addLabel('content-d');

    tl.pause();

    trail.tl = tl;
    trail.tween = null;

    // console.log('Constructor', this);
  }
  setTarget ( target ) {
    let trail = this;
    let tl = trail.tl;
    let {
      lead
    } = trail.selectors;

    target = trail.scrollReady ? target : 'content-0';

    if (!trail.scrollReady || !trail.quizReady) {
      target = 'content-0';
    }

    tl.pause();
    if (trail.tween) {
      trail.tween.kill();
      trail.tween = null;
    }

    // TODO Walking animation

    // console.log('Trail.setTarget', target);
    trail.tween = tl.tweenTo(target, { onComplete: function () {
      // TODO Stop walking animation
      // console.log('Trail tweenTo complete');
      switch (target) {
        case 'content-a':
          lead.classList.add('show-exclamation');
          break;
        case 'content-b':
          lead.classList.add('show-exclamation');
          break;
        case 'content-c':
          lead.classList.add('show-exclamation');
          break;
        case 'content-d':
          break;
      }
    }});
  }
  heroListen ( isHeroFixed ) {
    let trail = this;

    if (isHeroFixed) {
      trail.scrollReady = false;
      trail.setTarget('content-0');
    } else {
      trail.scrollReady = true;
    }
  }
  quizComplete () {
    let trail = this;
    trail.quizReady = true;
  }
}

export default TrailMobile;
