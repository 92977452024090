import { shuffle } from "../utilities";

class Mask {
  constructor() {
    let mask = this;

    let parent = document.getElementById('hero-mask');
    let childNodes = parent.querySelectorAll('.hero-mask-frame');
    let cover = parent.querySelector('.mask-cover');
    let children = [];
    for (let i = 0; i < childNodes.length; i++) {
      children.push(childNodes[i]);
    }

    mask.assets = {
      cover,
      children
    };

    // console.log('Constructor', this);
  }
  reveal () {
    let mask = this;
    let {
      cover,
      children
    } = this.assets;

    let duration = 100;

    setTimeout(() => {
      cover.classList.add('hide');
      for (let i = children.length - 1; i > 0; i--) {

        let inverseI = children.length - i;
        let child =  children[i];

        setTimeout(() => {
          child.classList.add('hide');
          if (i === 1) {
            // mask.startMaskLoop();
            mask.showCharacters();
          }
        }, duration * inverseI);
      }

    }, duration);

    mask.startMaskLoop();
  }
  startMaskLoop () {
    let parent = document.getElementById('hero-mask-loop');
    let nodes = parent.querySelectorAll('.loop-frame');
    let active = null;
    let availableIndexes = [];
    let children = [];
    for (let i = 0; i < nodes.length; i++) {
      children.push(nodes[i]);
    }

    function getIndex () {
      if (availableIndexes.length === 0) {
        for (let i = 0; i < children.length; i++) {
          availableIndexes.push(i);
          shuffle(availableIndexes);
        }
      }
      return availableIndexes.pop();
    }

    setInterval(function () {
      requestAnimationFrame(function () {
        let index = getIndex();
        if (active) {
          active.classList.remove('is-visible');
        }
        active = children[index];
        active.classList.add('is-visible');
      });
    }, 2000);
  }
  showCharacters () {
    let mask = this;
    let parent = document.getElementById('hero-characters');
    let nodes = parent.querySelectorAll('[class*="wrap"]');
    let children = [];
    for (let i = 0; i < nodes.length; i++) {
      children.push(nodes[i]);
    }
    children.forEach((el, i) => {
      setTimeout(function () {
        el.classList.add('is-visible');
        setTimeout(function () {
          el.classList.add('is-resolved');
          if (i === children.length - 1) {
            setTimeout(function( ) {
              mask.animateCharacters();
            }, 500);
          }
        }, 100)
      }, 100 * i);
    });
  }
  animateCharacters () {
    let gangar = document.getElementById('gangar');
    let pikachu = document.getElementById('pikachu');
    let totodile = document.getElementById('totodile');
    let mudkip = document.getElementById('mudkip');
    let squirtle = document.getElementById('squirtle');
    let queue = [];
    let characters = {
      pikachu,
      totodile,
      mudkip,
      squirtle
    };

    gangar.classList.add('is-floating');

    setInterval(function () {
      let char = getCharacter();
    }, 1000);

    function getCharacter () {
      if (queue.length === 0) {
        for (let prop in characters) {
          queue.push(prop);
        }
        shuffle(queue);
      }
      let char = characters[queue.pop()];
      char.classList.add('is-jumping');
      setTimeout(function () {
        char.classList.remove('is-jumping');
      }, 750);
    }

    // console.log(characters);
  }
}

export default Mask;
