import { qs, qsArray } from '../utilities/index';
import { TimelineMax } from 'gsap/all';
import { TweenLite } from 'gsap/all';
import ScrollStep from "./scrollStep";

class ContentManager {
  constructor() {
    let content = this;
    content.target = 'content-a';
    // content elements to animate
    //TODO: make sure hit areas like you did on characters?
    let contentA = document.getElementById('content-a');
    let triggerA = contentA.querySelector('.trigger');
    let contentB = document.getElementById('content-b');
    let triggerB = contentB.querySelector('.trigger');
    let contentC = document.getElementById('content-c');
    let triggerC = contentC.querySelector('.trigger');
    let contentD = document.getElementById('content-d');
    let triggerD = contentD.querySelector('.trigger');

    content.selectors = {
      contentA,
      triggerA,
      contentB,
      triggerB,
      contentC,
      triggerC,
      contentD,
      triggerD
    };

    content.order = [
      contentA,
      contentB,
      contentC,
      contentD
    ];

    let options = {
      root: null,
      rootMargin: "0px",
      threshold: [0, 0.25, 0.5, 0.75, 1]
    };
    let contentObserver = new IntersectionObserver(handleContentIntersect, options);
    contentObserver.observe(triggerA);
    contentObserver.observe(triggerB);
    contentObserver.observe(triggerC);
    contentObserver.observe(triggerD);

    function handleContentIntersect (entries) {
      entries.forEach(entry => {
        let contentID = entry.target.getAttribute('data-id');
        let el = content.selectors[contentID];
        if (entry.intersectionRatio >= 0.5 && !el.classList.contains('is-visible')) {
          el.classList.add('is-visible');
          content.change();
        } else if (entry.intersectionRatio <= 0.25 && entry.boundingClientRect.top > 0 && el.classList.contains('is-visible')) {
          el.classList.remove('is-visible');
          content.change();
        }
      });
    }
  }
  update ( isHeroFixed ) {
    let content = this;
    let {
      contentA,
      contentB,
      contentC,
      contentD
    } = content.selectors;
    if (isHeroFixed) {
      contentA.classList.remove('is-active');
      contentB.classList.remove('is-active');
      contentC.classList.remove('is-active');
      contentD.classList.remove('is-active');
    } else {
      contentA.classList.add('is-active');
      contentB.classList.add('is-active');
      contentC.classList.add('is-active');
      contentD.classList.add('is-active');
    }
    content.change();
  }
  change () {
    let content = this;

    let target = 'content-0';
    content.order.forEach((c) => {
      if (c.classList.contains('is-visible')) {
        target = c.id;
      }
    });

    content.target = target;

    if (content.onChange) {
      // console.log('onChange', target);
      content.onChange( target );
    }
  }
  getTarget () {
    return this.target;
  }
}

export default ContentManager;
