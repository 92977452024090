import { shuffle, getRandomInt } from "../utilities";

const COLORS = [
  'blue',
  'green',
  'orange',
  'pink',
  'yellow'
];
const VARIATION = [
  'a',
  'b',
  'c',
  'd'
];
const TOTAL = 15;
const X_RANGE = 60;
const Y_RANGE = 60;
const DELAY = 2000;

class WatercolorFx {
  constructor() {
    let fx = this;

    let nodes = document.querySelectorAll('.watercolor-fx');
    let parents = [];
    let colors = [];
    let variation = [];

    for (let i = 0; i < nodes.length; i++) {
      parents.push(nodes[i]);
    }

    parents.forEach(p => {
      let color = getColor();
      p.classList.add(color);
      for(let i = 0; i < TOTAL; i++) {
        let splotch = document.createElement('div');
        splotch.classList.add('splotch');
        let x = getRandomInt(-X_RANGE, X_RANGE);
        let y = getRandomInt(-Y_RANGE, Y_RANGE);
        splotch.style.transform = `translate(${x}%, ${y}%)`;
        let waterA = document.createElement('div');
        let v = getVariation();
        waterA.classList.add(`watercolor-${v}`);
        let delay = Math.round(DELAY / (i + 1));
        waterA.style.transitionDelay = `${delay}ms`;
        splotch.appendChild(waterA);
        p.appendChild(splotch);
      }
    });

    function getVariation () {
      if (variation.length === 0) {
        variation = VARIATION.slice(0);
        shuffle(variation);
      }
      return variation.pop();
    }

    function getColor () {
      if (colors.length === 0) {
        colors = COLORS.slice(0);
        shuffle(colors);
      }
      return colors.pop();
    }

    // console.log('Constructor', this);
  }
}

export default WatercolorFx;
