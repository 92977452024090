import Hero from './hero';
import Mask from "./mask";
import ContentManager from './content-manager';
import Quiz from "./quiz";
import Trail from './trail';
import TrailMobile from './trail-mobile';

class Home {
  constructor() {

    let hero = new Hero();

    let mask = new Mask();

    let quiz = new Quiz();

    let trail = new Trail();
    let trailMobile = new TrailMobile();

    // start loader logic
    let queue = new createjs.LoadQueue(false);
    let manifest = [];
    let nodes = document.querySelectorAll('[data-img-src]');
    let elements = [];
    for (let i = 0; i < nodes.length; i++) {
      elements.push(nodes[i]);
    }
    elements.forEach(el => {
      let src = el.getAttribute('data-img-src');
      let type = el.getAttribute('data-img-type');
      type = type === null ? 'png' : type;
      type = '.' + type;
      src += Modernizr.webp ? '.webp' : type;
      manifest.push(src);
    });
    queue.on("complete", loadComplete);
    queue.loadManifest(manifest);

    function loadComplete () {
      elements.forEach(el => {
        let src = el.getAttribute('data-img-src');
        let type = el.getAttribute('data-img-type');
        type = type === null ? 'png' : type;
        type = '.' + type;
        src += Modernizr.webp ? '.webp' : type;
        el.style.backgroundImage = `url('${src}')`;
      });
      document.body.classList.add('loaded');
      mask.reveal();
    }
    // end loading logic

    // let desktopTrail = new Trail();
    // let mobileTrail = new TrailMobile();
    let contentManager = new ContentManager();
    contentManager.onChange = function ( target ) {
      trail.setTarget( target );
      trailMobile.setTarget( target );
    };
    hero.onHeroUpdate = function ( isHeroFixed ) {
      trail.heroListen( isHeroFixed );
      trailMobile.heroListen( isHeroFixed );
      contentManager.update( isHeroFixed );
    };
    hero.update();
    quiz.onQuizComplete = function () {
      trail.quizComplete();
      trail.setTarget( contentManager.target );
      trailMobile.quizComplete();
      trailMobile.setTarget( contentManager.target );
    }

    // console.log('Constructor', this);
  }
}

export default Home;
