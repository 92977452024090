import { TimelineMax, Power2, CSSPlugin } from 'gsap/all';

const plugins = [ CSSPlugin ];

let wobbleDuration = 0.3;
let sway = 5;

class WalkTl {
  constructor( graphic, invert ) {
    let w = this;
    let tl = new TimelineMax();
    let tween = null;
    tl.to(graphic, { rotation: 0 });
    tl.addLabel('start');
    tl.to(graphic, wobbleDuration / 2, { rotation: sway * invert, ease: Power2.easeOut });
    tl.addLabel('loop-start');
    tl.to(graphic, wobbleDuration, { rotation: -sway * invert, ease: Power2.easeInOut });
    tl.to(graphic, wobbleDuration, { rotation: sway * invert, ease: Power2.easeInOut });
    tl.addLabel('loop-end');
    tl.to(graphic, wobbleDuration / 4, { rotation: 0, ease: Power2.easeOut });
    tl.addLabel('end');
    tl.pause();

    w.tl = tl;
    w.tween = tween;
    w.started = false;
    w.loopActive = false;
  }
  start () {
    let w = this;
    let tl = w.tl;

    if (w.started || w.loopActive) {
      return;
    }

    w.started = true;
    w.tween = tl.tweenFromTo('start', 'loop-start', {
      onComplete: function () {
        w.tween = tl.tweenFromTo('loop-start', 'loop-end', { repeat: -1 });
        w.loopActive = true;
      }
    });
  }
  stop () {
    let w = this;
    let tl = w.tl;

    if (!w.started) {
      return;
    }

    tl.pause();
    if (w.tween) {
      w.tween.kill();
      w.tween = null;
    }
    w.loopActive = false;
    w.started = false;
    tl.tweenTo('end');
  }
  // destroy () {
  //   let w = this;
  //   if (w.tween) {
  //     w.tween.kill();
  //     w.tween = null;
  //   }
  //   w.tl.kill();
  //   w.tl = null;
  // }
}

export default WalkTl;
